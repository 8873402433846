import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import HeaderBlogsComponent from "../components/HeaderBlogsComponent.js";
import A21_04_2021 from "../components/blogs/A21_04_2021.js"

function BlogsPage() {
  React.useEffect(() => {
    document.body.classList.add("blogs-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("blogs-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <div className="wrapper">
        <HeaderBlogsComponent />
        <div className="section section-team text-center">
          <Container>
            <div className="team text-center">
              <Row>
                <Col md="4">
                  <A21_04_2021/>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default BlogsPage;

import React from "react"
import { useLocation } from "react-router-dom"


export default function Analytics() {
  const location = useLocation()
  React.useEffect(() => {
    window.gtag('send', 'page_view', {
        page_location: location,
        page_path: location.pathname,        
    });
  }, [location])
}


/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container,Button,Row,Col } from "reactstrap";

function FooterComponent() {
  const lat= 34.86081791807522
  const lng= 32.88589811374873
  return (
    <footer className="footer" data-background-color="main">
      <Container>
        <Row>
          <Col mid="8">
        <nav>
          <ul>
            <li>
              <a>
                Contact Us
              </a>
            </li>
          </ul>
          <ul>
            <li>
            <a style={{fontSize:8}}>
                  Email:
              </a>
              <a style={{fontSize:10}}>
              constantinouwinery@gmail.com
              </a>
            </li>
          </ul>
          <ul>
            <li>
            <a style={{fontSize:8}}>
                Phone:
              </a>
              <a style={{fontSize:10}}>
              +357 25 470370
              </a>
            </li>
          </ul>
          <ul>
            <li>
            <a style={{fontSize:8}}>
                Address:
              </a>
              <a className="address" onClick={(e)=> window.open("https://maps.google.com?q="+lat+","+lng )} style={{fontSize:10}}>
              Pera Pedi, Limassol
              </a>
            </li>
          </ul>
        </nav>
        </Col>
        <Col mid="4">
        <div className="copyright" id="copyright">
          <p>
          © {new Date().getFullYear()}, Constantinou Winery
          </p>
          <Button
          className="btn-icon btn-round"
          color="info"
          href="#pablo"
          onClick={(e) => window.open('https://www.instagram.com/constantinou.winery', '_blank', 'noopener,noreferrer')}
          >
        <i className="fab fa-instagram"></i>
        </Button>
        <Button
          className="btn-icon btn-round"
          color="info"
          href="#pablo"
          onClick={(e) => window.open('https://www.facebook.com/ConstantinouWinery', '_blank', 'noopener,noreferrer')}
          >
          <i className="fab fa-facebook-square"></i>
        </Button>
        </div>
        </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default FooterComponent;

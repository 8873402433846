import React from "react";

// reactstrap components
import { Button, Container,  Row, Col} from "reactstrap";
import { Link } from "react-router-dom";



import img1 from "../../../images/blogs/A21_04_2021/vine1.jpg";
import img2 from "../../../images/blogs/A21_04_2021/vine2.jpg";


function Article21_04_2021() {

  return (
    <>
    <div className="section section-team text-center">
          <Container style={{marginTop: 50}}>
          <h2 className="title">Bleeding – Budburst of the vineyard</h2>
          <Row>
          <Col md="4">
                  <img
                      alt="..."
                      className="img-fluid img-raised"
                      src={img1}
                  ></img>
                </Col>
                <Col md="8">
                <p>
          Bleeding consider to be a natural pathway of a vine. It is the first sign of transition from dormancy to active growth which takes place approximately 15 days before budburst. 
          The rise of the soil temperature ({'>'}10◦C at 25cm depth) enables the roots to become active. In general words, bleeding can be visible due to the fact that the vine releases liquids 
          from pruning cuttings. Bleeding let the vine grower know that the plant is healthy for next season’s growth. Generally, lasts for two weeks, but can last for a month as well. 
          Bleeding occurs on freshly pruned vines. The vines which pruned during autumn do not bleed so the vine grower must have that in mind. 
          </p>
          <p>
          Budburst occurs when the mean daily temperature reaches about 10◦C (variety dependant). There are 8 phenological stages of Budbreak which occurs in the vine (Scientific Approach): 
          </p>
            <ol style={{textAlign:'start'}}>
              <li>
              Winter Bud
              </li>
              <li>
              Bud Swell
              </li>
              <li>
              Green Shoot Tip
              </li>
              <li>
              Leaf Emergence
              </li>
              <li>
              Leave Unfolded
              </li>
              <li>
              Flower Clusters Visible
              </li>
              <li>
              Flowers Clusters Separated 
              </li>
              <li>
              Flowers Separated
              </li>
            </ol>
                </Col>
              </Row>
          <Row style={{marginTop: 20}}>
          <Col md="6">
          <p>
          In terms of the factors affecting Budburst/Budbreak which differ from season to season  can be the latitude/Altitude, Soil Type, Soil Humidity, Soil Colour, Moisture Content of 
          Canes, Pruning System, Exposure to Sunlight, Hormones, Genetics (Variety), Air and Root Temperature, Position of the Bud on the spur/cane. 
          </p>
          <p>
          In some growing regions due to the extreme climatic conditions might be necessary for the vine to delay the process of budbreak/budburst. As mentioned above, the vine has the 
          mechanism to start it’s growing phase when high temperatures occur. Some seasons early high temperatures might be followed from extreme low temperatures which might negatively 
          affect the buds. So our job us vine growers and winemakers is to protect those vines in order to give us a high quality raw material by the end of the growing season. Some of 
          the most effective techniques to achieve a delay on budburst are the late pruning during February and April. Regarding to articles those techniques of late pruning occurs in Ravaz. 
          The addition of Iron Sulphate treatments at the end of winter can delay budburst for one or two weeks, but on the flipped site, the addition of this treatment during autumn showed 
          opposite results on budbreak and budburst. 
          </p>
          <p>
          In general points Budburst takes place and might occur during April and May depending as said before on the grape cultivar, the altitude, the day and night average temperature 
          and many other factors. 
          </p>  
                </Col>
                <Col md="6">
                <img
                      alt="..."
                      className="img-fluid img-raised"
                      src={img2}
                  ></img>
                </Col>
              </Row>
          <p className="author">Panagiotis Constantinou – Oenologist</p>
          </Container>
        </div>
    </>
  );
}

export default Article21_04_2021;